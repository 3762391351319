import { persistentAtom } from "@nanostores/persistent";

export const $filterItemsWhileTyping = persistentAtom<boolean>(
	"options_filter-items-while-typing",
	false,
	{
		encode: JSON.stringify,
		decode: JSON.parse,
	},
);
